import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import {
  IoShieldCheckmark,
  IoCheckmarkCircle,
  IoHelpBuoy,
  IoDocuments,
  IoBarChart,
  IoArrowUpCircle,
  IoMailUnread,
  IoLockClosed,
  IoFlash,
  IoPeople,
} from "react-icons/io5";

import { useModal } from "../components/useModal";

import {
  CallToAction,
  CustomerCard,
  CustomerQuote,
  CustomerQuoteLarge,
  LogoScroller,
  Modal,
  PageHeader,
} from "../components/site/";
import { Container, Wrapper, Row, Box } from "../components/util";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

const Payroll = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const customerData = useStaticQuery(graphql`
    query {
      allContentfulCustomerStory(
        filter: { name: { in: ["Pik 'n Mix", "1154 & Scopa"] } }
      ) {
        edges {
          node {
            name
            url
            heroQuote
            industry
            banner {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Online Payroll Software for NZ Businesses"
        description="Take care of your team on payday with smart, modern and seriously compliant online payroll for NZ businesses. Get started today."
        pathname={props.location.pathname}
      />
      <PageHeader
        feature="Payroll"
        title={[
          "Online Payroll Software ",
          <span className="-fontPrimary">for NZ Businesses</span>,
        ]}
        description="Pay employees right with smart, modern online payroll software that's built for compliance."
        imagePath="PayHero_Dashboard_Power.png"
        showVideo
      />
      <Container>
        <Wrapper css={{ paddingTop: "20px" }}>
          <LogoScroller>
            <Image
              filename="FlexiTimeHospitalityCustomers.png"
              alt="PayHero Hospitality Payroll Customers"
              centerImage
            />
          </LogoScroller>
          <CustomerQuote
            quote="I can’t fault the system, it works very well, and we will be sticking with it for many years to come."
            name="Hayden Collier"
            company="iStore New Zealand"
            pic="iStore_Circle.png"
            center
            large
          />
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoLockClosed />
                <br />
                Payroll compliance made easy
              </h3>
              <p>
                Relax safe in the knowledge that your payroll system follows the
                very latest Holidays Act guidance from MBIE and legislative
                updates are automatically applied. Just business as usual as far
                as you’re concerned.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoFlash />
                <br />
                Fast, easy, accurate & stress-free
              </h3>
              <p>
                Pay your staff in minutes and let our powerful online payroll
                software do the hard work for you. Take care of all your wage
                and leave calculations, payslips and payday filing in a few
                clicks.
              </p>
            </Box>
            <Box size={33}>
              <h2 className="h3">
                <IoPeople />
                <br />
                Payroll your employees can trust
              </h2>
              <p>
                Annual leave and holiday laws are complex, but we’ll make it
                easier to understand your obligations and for your staff to
                trust the entitlements and deductions in their pay.
              </p>
            </Box>
          </Row>
          <CustomerQuoteLarge
            quote="PayHero is easy to navigate and if changes need to be made, they can be made quickly and easily."
            name="Brett Pearson"
            company="Eat My Lunch"
            hero="EMLStoryHero.jpg"
            pic="Brett_Circle.png"
            logo="EML_Logo.png"
            videoLink="https://player.vimeo.com/video/721246626"
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter -center" css={{ maxWidth: "950px" }}>
            A better way to payroll
            <br />
            <span className="-fontLight">
              Take care of your team on payday with easy to use payroll software
              that calculates employee pay, taxes & deductions, and keeps track
              of employee leave.
            </span>
          </h2>
          <Row stackGap={80} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="PayHero_EmpMobile.png"
                alt="Review Timesheets, Leave & Expenses Before You Run Payroll | PayHero"
                centerImage
                maxWidth={550}
                addShadow
              />
            </Box>
            <Box size={50} stackGap={50}>
              <div>
                <h3>Perfect for hourly employees</h3>
                <p>
                  Track hourly work with ease and automatically calculate pay
                  and entitlements for part time, casual, contract or shift
                  workers. Let staff record hours against different work and
                  cost centres with online & mobile timesheets, or capture exact
                  start and finish times with photo clock in/out or GPS location
                  tracking.
                </p>
              </div>
              <div>
                <h3>Put your payroll on Autopilot</h3>
                <p>
                  You can set your pays to run automatically each payday. We’ll
                  send you an email with all the details and you can finalise
                  the pay there and then without having to login. Too easy!
                </p>
              </div>
              <div>
                <h3>Payday filing, sorted once and for all</h3>
                <p>
                  PayHero makes <Link to="/payday-filing">payday filing</Link>{" "}
                  easy – just connect your account to IRD and we’ll
                  automatically send the details through after every pay.
                </p>
              </div>
              <div>
                <h3>Public holidays are for relaxing</h3>
                <p>
                  No more painstaking public holiday calculations or costly
                  errors! Save on paperwork and manual processing by using
                  timesheet history to automatically calculate holiday
                  entitlements for variable hour employees.
                </p>
              </div>
            </Box>
          </Row>
          <Row stackGap={30} css={{ alignItems: "stretch" }}>
            {customerData.allContentfulCustomerStory.edges.map((edge, i) => {
              return (
                <Box size={50} key={i}>
                  <CustomerCard
                    slug={edge.node.url}
                    banner={edge.node.banner.gatsbyImageData}
                    logo={edge.node.logo.gatsbyImageData}
                    quote={edge.node.heroQuote}
                    industry={edge.node.industry}
                    company={edge.node.name}
                  />
                </Box>
              );
            })}
          </Row>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50}>
              <div>
                <h3>Seamlessly manage paid and unpaid breaks</h3>
                <p>
                  Employees can record breaks on the PayHero{" "}
                  <Link to={process.env.GATSBY_SITE_URL + '/time'}>time clock</Link> app, and PayHero will
                  automatically figure out if the break should be a paid
                  10-minute rest break or an unpaid 30-minute meal break.
                  Minimise the burden on managers and allow employees to
                  document their breaks themselves.
                </p>
              </div>
              <div>
                <h3>Update your Xero accounts</h3>
                <p>
                  <Link to="/xero">Connect with Xero</Link> and automatically
                  send wage data through to your accounting system. Keep it
                  simple or assign wage costs to different account codes and
                  tracking categories for those who need a more detailed break
                  down.
                </p>
              </div>
              <div>
                <h3>Online payroll software that works the way you do</h3>
                <p>
                  Your setup can be as simple or complex as you need it to be.
                  Easily manage multi-pay frequencies, different pay rates for
                  different work, a combination of hourly and salaried staff,
                  expenses, commissions, bonuses, employee loans and more.
                </p>
              </div>
              <div>
                <h3>Easy payments</h3>
                <p>
                  When you finalise a pay, you can use Pay Now to transfer the
                  funds with a POLi payment, or use our industry standard bank
                  payment batch file, leaving you in complete control of your
                  finances.
                </p>
              </div>
            </Box>
            <Box size={50}>
              <Image
                filename="SendPayModal_Graphic_PayHero.png"
                alt="Send Payslips to Employees, Send Invoices to Xero & Submit Pays to Inland Revenue | PayHero"
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Take PayHero for a test flight"
        description="Try payroll for free with our fully interactive online demo."
        showDemoButton
        hideTrialButton
      />
      <Container className="primary">
        <Wrapper>
          <h4 className="h2 -textCenter">Made for the web</h4>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Always accurate
              </h3>
              <p>
                NZ payroll and labour laws often change. Every time they do, we
                update PayHero so you’re always fully compliant with tax and
                holiday legislation. No downloads or time consuming updates for
                you – just business as usual.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Seriously secure
              </h3>
              <p>
                We use bank-level security and automatic, daily online backups
                to multiple servers to ensure your sensitive payroll data and
                employee information is kept safe.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Access from anywhere
              </h3>
              <p>
                Pay your wages from any computer or tablet with an internet
                connection. Let your employees manage their time, leave &
                expenses and view their payslips with powerful{" "}
                <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>employee access</Link>.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper className="-textCenter" noPaddingBottom>
          <h2>All the tools you need to supercharge your payroll</h2>
          <Box stackGap={40}>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoCheckmarkCircle />
                  <br />
                  Easy set up
                </h3>
                <p>
                  It couldn’t be easier to get started. There’s no setup fee, we
                  offer a 14 day free trial and we can help you load your
                  employees into the payroll system so you’re ready to go.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHelpBuoy />
                  <br />
                  Expert customer care
                </h3>
                <p>
                  Our team of NZ payroll experts are on hand to help you with
                  setup or any curly questions. Choose between phone, email or
                  online articles.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoDocuments />
                  <br />
                  Employee documents
                </h3>
                <p>
                  Save employment documents like contracts, permits, licences
                  and IRD forms on the employee record. Get rid of the old
                  filing cabinet and manage your most important documents
                  securely online.
                </p>
              </Box>
            </Row>
            {/* <Row stackGap={40}>
              <Box size={33}>
                <h3>Better with timesheets</h3>
                <p>
                  Go paperless with integrated online payroll and{" "}
                  <Link to={process.env.GATSBY_SITE_URL + '/time'}>timesheets</Link>. Reduce data entry,
                  increase accuracy and effortlessly keep records of who worked
                  when.
                </p>
              </Box>
              <Box size={33}>
                <h3>Easy expense reimbursements</h3>
                <p>
                  Let employees submit expense claims with photos of receipts
                  from their mobile app and reimburse those expenses at the same
                  time as you pay them.
                </p>
              </Box>
              <Box size={33}>
                <h3>Take a break from employee admin</h3>
                <p>
                  Give employees controlled{" "}
                  <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>self-service</Link> access to update
                  details, view pay history and submit timesheets, leave
                  requests and expense claims. Let staff manage their own info,
                  so you don’t need to.
                </p>
              </Box>
            </Row> */}
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoBarChart />
                  <br />
                  Powerful reporting
                </h3>
                <p>
                  Set up custom reports for a detailed view of employee hours
                  worked, holiday / leave liability, wage costs on a job or
                  almost anything else you can think of.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoArrowUpCircle />
                  <br />
                  Minimum wage top up
                </h3>
                <p>
                  Ensure that salaried staff who work variable hours, and
                  employees paid based on piecemeal rates, are always paid at
                  least the minimum wage for their actual hours of work. PayHero
                  will monitor hourly rates and automatically apply a top up if
                  they drop below minimum wage.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoMailUnread />
                  <br />
                  Payslip software
                </h3>
                <p>
                  Payslips are automatically emailed to employees when you
                  finalise a pay. Add your company logo to your payslip template
                  and select which information is shown to employees.
                </p>
              </Box>
            </Row>
          </Box>
          <CustomerQuote
            quote="The responsiveness and helpfulness to any questions asked makes all the difference."
            name="Hilary Fleming"
            company="The Takeovers Panel"
            pic="TheTakeoversPanel_Circle.png"
            large
            center
          />
          <Image
            filename="PayHeroHero_Cropped.png"
            alt="Payroll for NZ Businesses"
            maxWidth={1100}
            centerImage
            addShadow
          />
        </Wrapper>
      </Container>
      <CallToAction
        title={[
          <span className="-fontPrimary">Easily manage payroll</span>,
          <br className="-hideOnMobile" />,
          " & pay your staff right",
        ]}
        hideDesc
        showSalesButton
        showPricingButton
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Payroll;
